var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_sparepart_request_form") } },
    [
      _c(
        "a-form-model",
        {
          attrs: {
            "data-testid": "sparepart-request-form",
            "label-align": "left",
            "wrapper-col": { span: 12 },
            "label-col": { span: 9 }
          }
        },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_document_number") } },
                    [
                      _c("SelectSparepartRequest", {
                        attrs: { "label-in-value": "" },
                        model: {
                          value: _vm.filter.documentNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.filter, "documentNumber", $$v)
                          },
                          expression: "filter.documentNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_request_date") } },
                    [
                      _c("a-range-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          format: _vm.DEFAULT_DATE_FORMAT,
                          placeholder: [
                            _vm.$t("lbl_start_date"),
                            _vm.$t("lbl_end_date")
                          ]
                        },
                        model: {
                          value: _vm.filter.requestDate,
                          callback: function($$v) {
                            _vm.$set(_vm.filter, "requestDate", $$v)
                          },
                          expression: "filter.requestDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_branch") } },
                    [
                      _c("SelectBranch", {
                        attrs: { "label-in-value": "" },
                        model: {
                          value: _vm.filter.branch,
                          callback: function($$v) {
                            _vm.$set(_vm.filter, "branch", $$v)
                          },
                          expression: "filter.branch"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_mechanic") } },
                    [
                      _c("SelectMechanic", {
                        attrs: {
                          "label-in-value": "",
                          placeholder: _vm.$t("common.select-text", {
                            text: _vm.$t("lbl_mechanic")
                          })
                        },
                        model: {
                          value: _vm.filter.mechanic,
                          callback: function($$v) {
                            _vm.$set(_vm.filter, "mechanic", $$v)
                          },
                          expression: "filter.mechanic"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_status") } },
                    [
                      _c("SelectSparepartRequestStatus", {
                        model: {
                          value: _vm.filter.status,
                          callback: function($$v) {
                            _vm.$set(_vm.filter, "status", $$v)
                          },
                          expression: "filter.status"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "a-space",
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: {
                                    "data-testid": "sparepart-request-btn-reset"
                                  },
                                  on: { click: _vm.handleReset }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("lbl_reset")) + " "
                                  )
                                ]
                              ),
                              _c(
                                "a-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    loading: _vm.loading.find,
                                    "data-testid": "sparepart-request-btn-find"
                                  },
                                  on: { click: _vm.handleFind }
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 12, align: "end" } },
                        [
                          _vm.$can("create", "spare-part-request-desktop") ||
                          _vm.$can("update", "spare-part-request-desktop")
                            ? _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "logistic.create.spare-part-request"
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "a-button",
                                    { attrs: { type: "primary" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("lbl_create_new")) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c("a-table", {
                    attrs: {
                      size: "small",
                      dataSource: _vm.dataSource.data,
                      columns: _vm.columns,
                      "row-key": "id",
                      loading: _vm.loading.find,
                      pagination: {
                        showTotal: function() {
                          return _vm.$t("lbl_total_items", {
                            total: _vm.dataSource.totalElements
                          })
                        },
                        total: _vm.dataSource.totalElements,
                        showSizeChanger: true,
                        pageSizeOptions: _vm.PAGE_SIZE_OPTIONS,
                        current: _vm.pagination.page,
                        pageSize: _vm.pagination.limit
                      },
                      "row-class-name": function(_record, index) {
                        return index % 2 ? "bg-white" : "bg-gray-light"
                      },
                      "data-testid": "sparepart-request-table"
                    },
                    on: { change: _vm.onChangeTable },
                    scopedSlots: _vm._u([
                      {
                        key: "action",
                        fn: function(text, record) {
                          return [
                            _c(
                              "a-space",
                              [
                                _vm.$can(
                                  "create",
                                  "spare-part-request-desktop"
                                ) ||
                                _vm.$can("update", "spare-part-request-desktop")
                                  ? [
                                      _c(
                                        "RouterLink",
                                        {
                                          attrs: {
                                            to: _vm.getNavigation(
                                              record.id,
                                              record.status
                                            )
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("lbl_view")) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]
                                  : _c(
                                      "RouterLink",
                                      {
                                        attrs: {
                                          to: {
                                            name:
                                              "logistic.spare-part-request.detail",
                                            params: { id: record.id }
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.$t("lbl_view")) + " "
                                        )
                                      ]
                                    ),
                                _vm.$can("create", "work-order") &&
                                _vm.allowToCreate(record)
                                  ? [
                                      _c("a-divider", {
                                        attrs: { type: "vertical" }
                                      }),
                                      _c(
                                        "RouterLink",
                                        {
                                          attrs: {
                                            to: {
                                              name:
                                                "logistic.spare-part-request.work-order.create",
                                              params: { id: record.id }
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("common.create-text", {
                                                  text: _vm.$t("lbl_work_order")
                                                })
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24, align: "end" } },
                [
                  _vm.$can("read", "spare-part-request-desktop")
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            disabled: _vm.dataSource.totalElements === 0,
                            loading: _vm.loading.download,
                            "data-testid": "sparepart-request-btn-download"
                          },
                          on: { click: _vm.handleDownload }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }