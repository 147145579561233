




























































































































































import { SearchBuilder } from "@/builder";
import SelectBranch from "@/components/custom/select/SelectBranch.vue";
import SelectMechanic from "@/components/custom/select/SelectMechanic.vue";
import SelectSparepartRequest from "@/components/custom/select/SelectSparepartRequest.vue";
import SelectSparepartRequestStatus from "@/components/custom/select/SelectSparepartRequestStatus.vue";
import dateFormat from "@/filters/date.filter";
import { trimSpaceToUnderscore } from "@/helpers/common";
import { APagination, useBlob, useDate } from "@/hooks";
import { SparePartRequestMapper } from "@/mapper/SparepartRequest.mapper";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  ONE,
  PAGE_SIZE_OPTIONS,
} from "@/models/constant/global.constant";
import { Pagination } from "@/models/constant/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { EnumSparePartRequestStatus } from "@/models/enums/SparePartRequest.enum";
import { SparepartResponseDto } from "@/models/interface/sparepart-request";
import { sparePartRequestService } from "@/services/sparepart-request.service";
import { LabelInValue, SorterProps } from "@/types";
import { StringUtils } from "@/utils";
import { Moment } from "moment";
import { Component, Vue } from "vue-property-decorator";

interface Filter {
  documentNumber: LabelInValue | undefined;
  requestDate: Moment[] | null;
  mechanic: LabelInValue | undefined;
  status: string | undefined;
  branch: LabelInValue | undefined;
}

@Component({
  components: {
    SelectMechanic,
    SelectSparepartRequestStatus,
    SelectSparepartRequest,
    SelectBranch,
  },
})
export default class SparepartRequest extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;

  defaultFilters: Filter = {
    requestDate: null,
    documentNumber: undefined,
    mechanic: undefined,
    status: undefined,
    branch: undefined,
  };

  filter: Filter = {
    ...this.defaultFilters,
  };

  columns = [
    {
      title: this.$t("lbl_document_number"),
      dataIndex: "documentNo",
      key: "documentNo",
      customRender: text => text || "-",
      sorter: true,
    },
    {
      title: this.$t("lbl_request_date"),
      dataIndex: "requestDate",
      key: "requestDate",
      customRender: text => dateFormat(text),
      sorter: true,
    },
    {
      title: this.$t("lbl_mechanic"),
      dataIndex: "mechanicName",
      key: "mechanicName",
      customRender: text => text || "-",
      sorter: true,
    },
    {
      title: this.$t("lbl_branch"),
      dataIndex: "branchName",
      key: "branchName",
      customRender: text => text || "-",
      sorter: true,
    },
    {
      title: this.$t("lbl_status"),
      dataIndex: "status",
      key: "status",
      customRender: text => text || "-",
      sorter: true,
    },
    {
      title: this.$t("lbl_action"),
      key: "action",
      scopedSlots: { customRender: "action" },
    },
  ];

  loading = {
    find: false,
    download: false,
  };

  pagination = {
    page: +this.$route?.query.p || DEFAULT_PAGE,
    limit: +this.$route?.query.l || DEFAULT_PAGE_SIZE,
    reset(): void {
      this.page = DEFAULT_PAGE;
      this.limit = DEFAULT_PAGE_SIZE;
    },
  };

  dataSource: Pagination<SparepartResponseDto> = {
    currentPage: 0,
    data: [],
    totalElements: 0,
    totalPages: 0,
  };

  created(): void {
    this.fetchSparepartRequests(this.buildQueryParams());
  }

  allowToCreate({ status }): boolean {
    return (
      StringUtils.compare(status, "Unprocessed") ||
      StringUtils.compare(status, "Partially Processed")
    );
  }

  fetchSparepartRequests(
    params: RequestQueryParams = new RequestQueryParams()
  ): void {
    this.loading.find = true;
    sparePartRequestService
      .listSparepartRequest(params)
      .then(res => {
        this.dataSource = res;
      })
      .finally(() => (this.loading.find = false));
  }

  buildQueryParams(): RequestQueryParams {
    const { toStartDay, toEndDay } = useDate();

    const params = new RequestQueryParams();
    const builder = new SearchBuilder();
    const queries: string[] = [];

    params.sorts = "documentNo:desc";
    params.limit = this.pagination.limit;
    params.page = this.pagination.page - ONE;

    if (this.filter.documentNumber?.label) {
      queries.push(
        builder.push(["documentNo", this.filter.documentNumber.label]).build()
      );
    }

    if (this.filter.mechanic) {
      queries.push(
        builder.push(["mechanic.secureId", this.filter.mechanic.key]).build()
      );
    }

    if (this.filter.requestDate) {
      const [start, end] = this.filter.requestDate;
      if (!!start && !!end) {
        queries.push(
          builder
            .push(["requestDate", toStartDay(start).format()], {
              het: true,
            })
            .and()
            .push(["requestDate", toEndDay(end).format()], {
              let: true,
            })
            .build()
        );
      }
    }

    if (this.filter.status) {
      queries.push(builder.push(["status", this.filter.status]).build());
    }

    if (this.filter.branch?.key) {
      queries.push(
        builder.push(["branch.secureId", this.filter.branch.key]).build()
      );
    }

    params.search = queries.join(builder.AND);

    return params;
  }

  buildDownloadQueryParams(): RequestQueryParams {
    const { toStartDay, toEndDay } = useDate();

    const params = new RequestQueryParams();
    const builder = new SearchBuilder();
    const queries: string[] = [];

    params.sorts = "documentNo:desc";
    params.limit = this.pagination.limit;
    params.page = this.pagination.page - ONE;

    if (this.filter.documentNumber?.label) {
      queries.push(
        builder.push(["documentNo", this.filter.documentNumber.label]).build()
      );
    }

    if (this.filter.mechanic) {
      queries.push(
        builder.push(["mechanicId", this.filter.mechanic.key]).build()
      );
    }

    if (this.filter.requestDate) {
      const [start, end] = this.filter.requestDate;
      if (!!start && !!end) {
        queries.push(
          builder
            .push(["requestDate", toStartDay(start).format()], {
              het: true,
            })
            .and()
            .push(["requestDate", toEndDay(end).format()], {
              let: true,
            })
            .build()
        );
      }
    }

    if (this.filter.status) {
      queries.push(builder.push(["srfStatus", this.filter.status]).build());
    }

    if (this.filter.branch?.key) {
      queries.push(builder.push(["branchId", this.filter.branch.key]).build());
    }

    params.search = queries.join(builder.AND);

    return params;
  }

  getSortKey(columnKey: string): string {
    if (columnKey === "mechanicName") {
      return "mechanic.firstName";
    }

    return columnKey;
  }

  onChangeTable(pagination: APagination, _filter, sorts: SorterProps): void {
    const { current, pageSize } = pagination;
    const { columnKey, order } = sorts;

    const hasNextPage = pageSize === this.pagination.limit;
    this.pagination.page = hasNextPage ? current : DEFAULT_PAGE;
    this.pagination.limit = pageSize;

    const params = this.buildQueryParams();
    if (order && order === "ascend") {
      params.sorts = `${this.getSortKey(columnKey)}:asc`;
    } else if (order && order === "descend") {
      params.sorts = `${this.getSortKey(columnKey)}:desc`;
    }

    this.fetchSparepartRequests(params);
  }

  handleReset(): void {
    this.pagination.reset();
    this.filter = { ...this.defaultFilters };

    this.fetchSparepartRequests(this.buildQueryParams());
  }

  handleFind(): void {
    this.pagination.reset();
    this.fetchSparepartRequests(this.buildQueryParams());
  }

  handleDownload(): void {
    const { toStartDay, toEndDay, toDefaultFormat } = useDate();
    const { toDownload } = useBlob();

    const params = this.buildDownloadQueryParams();
    params.limit = this.dataSource.totalElements;

    let startDate: string | undefined;
    let endDate: string | undefined;
    if (this.filter.requestDate && this.filter.requestDate.length === 2) {
      const [start, end] = this.filter.requestDate;
      startDate = toDefaultFormat(toStartDay(start));
      endDate = toDefaultFormat(toEndDay(end));
    }

    const downloadParams = SparePartRequestMapper.mapDownloadParams(params, {
      documentNo: this.filter.documentNumber?.label,
      mechanicName: this.filter.mechanic?.label.trim() ?? undefined,
      requestEndDate: startDate,
      requestStartDate: endDate,
      status: this.filter.status,
      branchName: this.filter.branch?.label,
    });

    this.loading.download = true;
    sparePartRequestService
      .downloadListSparepartRequests(downloadParams)
      .then(res => {
        toDownload(res, "sparepart_requests.xlsx");
      })
      .finally(() => {
        this.loading.download = false;
      });
  }

  getNavigation(id: string, status: EnumSparePartRequestStatus) {
    const details: EnumSparePartRequestStatus[] = [
      "CANCELLED",
      "FULLY_PROCESSED",
      "REJECTED",
      "UNPROCESSED",
    ];
    if (
      details.includes(
        trimSpaceToUnderscore(status) as EnumSparePartRequestStatus
      )
    ) {
      return {
        name: "logistic.spare-part-request.detail",
        params: { id },
      };
    }

    return {
      name: "logistic.spare-part-request.edit",
      params: { id },
    };
  }
}
